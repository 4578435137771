<template>
    <div>
        
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :items-per-page=itemsPerPage
            :loading=loadingTable
            :key="`list-promotion-${tenantId}`"
            
            class="elevation-1"
            item-key="agreement_id"
            dense
        >
            <template v-slot:top>
                <v-row >
                    <v-col cols=2 md=3  class='text-left'>
                        <v-toolbar-title class="ml-md-4">
                            <v-icon class="mb-1">mdi-thumb-up-outline</v-icon> ACUERDOS
                        </v-toolbar-title>
                    </v-col>
                    <v-col  md=3>
                        <v-text-field
                            search
                            v-model=search
                            label="Buscar Por cliente"
                            @keyup.enter="getAgreements()"
                            append-icon="mdi-magnify"
                            @click:append="getAgreements()"
                        />
                    </v-col>
                    <v-col  md=3>
                        <v-text-field
                            search
                            v-model=searchProducto
                            label="Buscar por producto"
                            append-icon="mdi-magnify"
                            @keyup.enter="getAgreements()"
                            @click:append="getAgreements()"
                        />
                    </v-col>
                    <v-col md=3 class='text-center'>
                        <v-btn 
                            small
                            color = 'primary'
                            @click="editAgreement()"
                        > 
                            <v-icon>mdi-plus</v-icon> Nuevo acuerdo
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.actions="{item}">
                <v-btn 
                    icon 
                    @click="editAgreement(item.agreement_id)"
                >
                    <v-icon color='warning' >mdi-pencil</v-icon>
                </v-btn>	
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialog" 
            max-width="1200"
            persistent
        >
            <v-col>
                
                <v-alert
                    dense
                    type="info"
                    icon="mdi-thumb-up-outline"
                >
                    ACUERDOS
                </v-alert>
                <v-form ref='form_agreement'>
                    <v-col cols=12> 
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-autocomplete
                                    :readonly="editCurrentAgreement && agreement.agreement_id !=null"
                                    v-model=agreement.party_id_to
                                    :items=clients
                                    item-value="party_id"
                                    item-text=""
                                    dense
                                    label="Cliente"
                                    :rules=required
                                >
                                    <template v-slot:item="{ item }">
                                        {{item.name1+''+(typeof item.name2 !='undefined' ? item.name2 :'')}}
                                    </template>
                                    <template v-slot:selection="{ attr, on, item, selected }">
                                        <span style="font-size:12px">
                                            {{item.name1+''+(typeof item.name2 !='undefined' ? item.name2 :'')}}
                                        </span>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3">
                            <v-menu
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="agreement.from_date"
                                            label="Desde"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="requiredMinDay"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        no-title
                                        v-model="agreement.from_date"
                                        @input="menu1 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="agreement.thru_date"
                                            label="Hasta"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            :rules="requiredMaxDay"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        no-title
                                        v-model="agreement.thru_date"
                                        @input="menu2 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model=agreement.description
                                    dense
                                    type="text"
                                    label="Descripción"
                                >
                                    <template v-slot:append-outer>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon 
                                                    color="success"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="storeAgreement"
                                                >mdi-floppy
                                                </v-icon>
                                            </template>
                                            <span>Guardar</span>
                                        </v-tooltip>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-form>
                <v-row v-if="agreement.agreement_id !=null">
                    <v-col cols=8>
                        <v-col>
                            <v-col class='text-center primary white--text py-0'>
                                PRODUCTOS
                            </v-col>
                            <div class="mt-4">
                                <v-autocomplete
                                    v-model=agreementProduct
                                    :items=agreementProducts
                                    :search-input.sync="syncAgreementProduct"
                                    item-text="internal_name"
                                    return-object
                                    dense
                                    label="Buscar producto"
                                    cache-items
                                    class="mx-4"
                                    flat
                                    clearable
                                    hide-no-data
                                    hide-details
                                    multiple
                                >
                                    <template v-slot:append>
                                        <v-tooltip bottom >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="success"
                                                    @click="searchAgreementProduct"
                                                    :loading=loadingAgreementSearch
                                                >
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Buscar</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:append-outer>
                                        <v-btn 
                                            color="success" class="mx-2" 
                                            @click="addAgreementProduct" 
                                            small 
                                        > Agregar producto </v-btn> 
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div>
                                <v-data-table
                                    :headers="productsHeaders"
                                    :items="agreement.products"
                                    item-key="product_id"
                                    class="elevation-1"
                                >
                                    <template v-slot:item.edit_pvp="{item}">
                                        <v-text-field
                                            type='number'
                                            prepend-icon='mdi-currency-usd'
                                            hide-details
                                            dense
                                            :rules=required
                                            v-model="item.price"
                                        >
                                            <template v-slot:append-outer>
                                                <v-btn
                                                    color='success'
                                                    icon
                                                    :loading=loadingProductAgreementStore
                                                    @click="storeProduct(item)"
                                                >
                                                    <v-icon color='success'>mdi-floppy</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    color='success'
                                                    icon
                                                    :loading=loadingProductAgreementDelete
                                                    @click="deleteProduct(item)"
                                                >
                                                    <v-icon color='red'>mdi-delete-forever</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-col>
                    </v-col>
                    <v-col cols=4>  
                        <v-col>
                            <v-col class='text-center primary white--text py-0'>
                                LÍNEA DE CRÉDITO
                            </v-col>
                        </v-col>
                        <v-form ref='form_credit_data'>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="agreement.monto"
                                    dense
                                    type="number"
                                    label="Monto del crédito"
                                    :rules=requiredMin
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="agreement.dias_plazo"
                                    dense
                                    type="number"
                                    label="Días de plazo"
                                    :rules=requiredMin
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="text-center">
                                <v-btn 
                                    v-if="successWorkrEffort"
                                    color="success"
                                    @click="approveCreditData"
                                    :loading=loadingStoreCreditData
                                >
                                    <v-icon>mdi-check</v-icon> Aprobar 
                                </v-btn>
                                <v-btn 
                                    v-else
                                    color="success"
                                    @click="storeCreditData"
                                    :loading=loadingStoreCreditData
                                >
                                    <v-icon>mdi-floppy</v-icon> Guardar 
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class='text-center'>
                <v-btn 
                    class="text-center" 
                    color='default' 
                    @click=closeDialog
                >
                    <v-icon>mdi-cancel</v-icon> Cerrar
                </v-btn>
            </v-col>
        </v-dialog>
    </div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex'
import moment from 'moment'
import Vue from 'vue'

export default {
    name : 'AgreementComponent',
    data: () => ({
        headers: [
			{ text: 'Código', value: 'agreement_id',align: 'start' },
			{ text: 'Cliente', value: 'name',align: 'start' },
            { text: 'descripción', value: 'description',align: 'start' },
            { text: 'Desde', value: 'from_date',align: 'center' },
            { text: 'Hasta', value: 'thru_date',align: 'center' },
            { text: 'Acciones', value: 'actions',align: 'center' }
        ],
        productsHeaders:[
            {text: 'ID', align: 'start', value: 'product_id'},
            {text: 'Producto', align: 'start', value: 'internal_name'},
            {text: 'Precio normal', align: 'start', value: 'pvp'},
            {text: 'Precio acuerdo', align: 'start', value: 'edit_pvp'},
        ],
        search:null,
        searchProducto: null,
        menu1:false,
        menu2:false,
        dataTable:[],
        clients:[],
        agreementProducts:[],
        agreement:{
            agreement_id:null,
            party_id_to: null,
            from_date:null,
            thru_date: null,
            description:null,
            monto:null,
            dias_plazo:null,
            aprobado:null,
            products:[]
        },
        defaultAgreement:{
            agreement_id:null,
            party_id_to: null,
            from_date:null,
            thru_date: null,
            description:null,
            monto:null,
            dias_plazo:null,
            aprobado:null,
            products:[]
        },
        agreementProduct:null,
        syncAgreementProduct:null,
        editCurrentAgreement:false,
        loadingProductAgreementStore:false,
        loadingProductAgreementDelete:false,
        successWorkrEffort:false,
        loadingStoreCreditData:false,
        loadingAgreementSearch:false,
        totalItems:1,
        currentPage:1,
        itemsPerPage:20,
        dialog:false,
        required:[v => !!v || 'El campo es requerido'],
        requiredMin:[
            v => !!v || 'El campo es requerido',
            v => (!!v && v > 0) || 'Debe escribir un número mayor a cero'
        ],
    }),
    watch:{
        syncAgreementProduct:function(val){
            val && val !== this.agreementProduct && this.searchAgreementProduct(val)
        },
    },
    computed:{
        
        ...mapState('master',['loadingTable','tenantId','paramAlertQuestion']),

        requiredMinDay(){
            
            return[
                (v) => {
                    if((v < moment().format('YYYY-MM-DD') && !this.editCurrentAgreement) || !v){
                        return 'La fecha debe ser mayor o igual a hoy'
                    }else{
                        return true
                    }
                }
            ]
        },

        requiredMaxDay(){
            return[
                (v) => {console.log(this.agreement.from_date)
                    if(v <= this.agreement.from_date || !v){
                        return 'La fecha debe ser mayor al inicio'
                    }else{
                        return true
                    }
                }
            ]
        },

    },
    methods:{

        ...mapMutations('master',['setUrl','setLoadingTable','setTitleToolbar','setLoadingBtn']),

        ...mapActions('master',['requestApi','alertNotification']),

        getAgreements(){
            
            this.setLoadingTable(true)
            this.setUrl('lista-acuerdos')
            this.dataTable=[]
            this.requestApi({
				method: 'GET',
				data: {
                    busCliente: this.search,
                    busProducto: this.searchProducto
                }
			}).then(res =>{
                
                console.log(res)
                this.totalItems = res.data.page_count
                this.dataTable = res.data._embedded.lista_acuerdos
                   
			}).then(()=>{
                this.setLoadingTable(false)
            })

        },

        editAgreement(agreement_id){

            this.setLoadingTable(true)
            this.setUrl(`lista-acuerdos/${agreement_id}`)
            this.requestApi({
				method: 'GET',
				data: {}
			}).then(res =>{
              
                this.editCurrentAgreement= typeof agreement_id != 'undefined'
                Object.assign(this.agreement, res.data.agreement)

                this.clients = typeof agreement_id == 'undefined'
                    ?   this.clients = res.data.clients.filter(cli => { 
                            return typeof this.dataTable.find(e => e.party_id_to == cli.party_id) == 'undefined'
                        })
                    :   res.data.clients

                this.dialog=true
                
                
                   
			}).then(()=>{
                this.setLoadingTable(false)
                
            })
    
        },

        closeDialog(){
            Object.assign(this.agreement, this.defaultAgreement)
            this.editCurrentAgreement=false
            this.dialog=false
        },

        approveCreditData(){

        },

        searchAgreementProduct(product){
           
            this.loadingAgreementSearch=true
            this.setUrl(`buscar-producto/${product}`)
            this.requestApi({
				method: 'GET',
				data :{}
			}).then(res =>{

                this.agreementProducts= res.data.products

			}).catch(()=>{
                
            }).then(()=>{
                this.loadingAgreementSearch=false
            })

        },

        addAgreementProduct(){

            this.agreementProduct.forEach(obj => {
                this.agreement.products.unshift({
                    internal_name: obj.internal_name,
                    price: obj.pvp,
                    product_id: obj.product_id,
                    pvp: obj.pvp,
                })
            });
            
            this.agreementProduct=[]
            this.agreementProducts=[]
            
        },

        storeProduct(product){
            this.setUrl('lista-partys')
            this.loadingProductAgreementStore=true
            
            this.requestApi({
				method: 'POST',
				data: {
                    typeStore:'storeProductAgreement',
                    agreementId:this.agreement.agreement_id,
                    ...product
                }
			}).then(res =>{

                this.agreement.products.forEach(product =>{
                    if(product.price == '' || product.price < 0)
                        product.price=0
                })

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })   

			}).catch(()=>{

            }).then(()=>{
                this.loadingProductAgreementStore=false
            })
        },

        deleteProduct(product){

            Vue.swal({
                html: "Está seguro de eliminar el producto del acuerdo?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {
                
                if (result.isConfirmed) {

                    console.log(this.agreement,product)
            
                    this.setLoadingBtn(true)
                    this.setUrl('lista-partys')

                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            typeDelete: 'deleteProductAgreement',
                            agreementId: this.agreement.agreement_id,
                            ...product
                        }
                    }).then(res =>{

                        let index = this.agreement.products.indexOf(product)
                        this.agreement.products.splice(index,1)

                        this.alertNotification({
                            param:{
                                html: res.data.detail
                            }
                        })   

                    }).then(()=>{
                        this.setLoadingBtn(false)
                    })

                }

            })

        },

        storeAgreement(){

            if(!this.$refs.form_agreement.validate())
                return false

            this.setUrl('lista-partys')

            const {
                agreement_id,
                party_id_to,
                from_date,
                thru_date,
                description
            } = this.agreement
            
            this.requestApi({
				method: 'POST',
				data: {
                    typeStore:'storeNewAgreement',
                    agreementId: agreement_id,
                    expeditionAgreement: from_date,
                    expirationAgreement: thru_date,
                    agreementDescription: description,
                    partyId: party_id_to
                }
			}).then(res =>{

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })  
                
                if(res.data.res.agreementId!=null && !this.editCurrentAgreement){
                    
                    this.closeDialog()
                    this.editAgreement(res.data.res.agreementId)
                    
                } else {
                    this.closeDialog()
                    this.getAgreements()
                }

			})

        },

        storeCreditData(){

            if(!this.$refs.form_credit_data.validate())
                return false

            this.loadingStoreCreditData=true
            this.setUrl('lista-partys')
            
            this.requestApi({
				method: 'POST',
				data: {
                    typeStore:'storeCreditData',
                    agreementId: this.agreement.agreement_id,
                    creditAmount: this.agreement.monto,
                    daysTerm:  this.agreement.dias_plazo,
                    partyId: this.agreement.party_id_to
                }
			}).then(res =>{

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })  

			}).catch(()=>{

            }).then(()=>{
                this.loadingStoreCreditData=false
            })

        },

    },
    created(){
        this.getAgreements()
    }
}
</script>

<style>

    .v-dialog{
        background-color: white;
    }

</style>